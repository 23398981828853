<template>
  <div class="warningBox">
    <div class="content_area searchBox globalShadow clearfix">
      <el-input v-model="input"
                :placeholder="$t('pleaseinputcompany')"
                style="width:930px"
                size="small"
                class="fl"></el-input>
      <el-button type="primary"
                 size="small"
                 style="width:130px"
                 @click="search"
                 class="fr">{{LOCALE=='en'?'Search':'搜索'}}</el-button>
    </div>
    <div class="listBox">
      <div class="itemBox globalShadow  content_area "
           v-for="(item,index) in listData"
           :key="index">
        <div class="clearfix  normalMsg">
          <p class="fl company">{{item.company_name}}</p>
          <p class="fl case">{{item.cases}}</p>
          <p class="fl del">{{item.delinquent}}</p>
          <p class="fl type">{{item.type}}</p>
          <p class="fr hanlde"
             @click="detailClick(index,index)"> {{item.isShow?$t('packup'):$t('open') }} <i :class="[item.isShow?'el-icon-arrow-up':'el-icon-arrow-down']"></i> </p>
        </div>
        <div class="detailMsg"
             :class="{'isShow':item.isShow}"
             style="line-height:25px;">
          {{item.content}}
        </div>

      </div>

    </div>
    <isAllDataTip v-if="listData.length && total === listData.length && !loading"></isAllDataTip>
    <isLoadingTip v-if="loading" />
    <div class="content_area"
         style="height:500px;position: relative;"
         v-if="listData.length == 0">
      <noDataImg ></noDataImg>
    </div>
  </div>
</template>

<script>
import isAllDataTip from "@/baseComponents/isAllDataTip";
import isLoadingTip from "@/baseComponents/isLoadingTip";

export default {
  metaInfo:{
    title:'风险公示'
  },
  data () {
    return {
      input: '',
      listData: [],
      total: 0,
      start: 1,
      loading: false
    }
  },
  mounted () {
    window.addEventListener('scroll', this.scrollEvent,false);
    if (this.$route.query && this.$route.query.company_name) {
      this.input = this.$route.query.company_name
    }
    this.$nextTick(() => {
      this.getDataList(1)
    })
  },
  destroyed () {
    window.removeEventListener('scroll', this.scrollEvent,false);
  },
  methods: {
    detailClick (item, index) {
      this.listData[index].isShow = !this.listData[index].isShow
    },
    async getDataList(type) {
      // 防止多次出发 请求事件
      window.removeEventListener('scroll', this.scrollEvent,false);
      let params = {
        limit: 10,
        company_name: this.input,
        start: this.start - 1,
        source: this.PJSource
      }
      this.loading = true
      let data = await this.$store.dispatch("API_company/getwarningList", params);
      this.loading = false
      if (data.success) {
        if (data.success) {
          data.data.forEach(element => {
            element.isShow = false
          });
          if(type==2){
            this.listData.push(...data.data)
          }else if(type==1){
            this.listData = data.data;
          }
          window.addEventListener("scroll", this.scrollEvent, false);
          this.total = data.count
        }
      }
    },
    scrollEvent(){
      if (document.documentElement.scrollTop + document.documentElement.clientHeight   >= document.body.scrollHeight- 354) {
        if(this.total!=this.listData.length){
          this.start++
          this.getDataList(2)
          // 防止多次出发 请求事件
          window.addEventListener('scroll', this.scrollEvent,false);
        }
      }
    },
    search () {
      this.start = 1
      this.getDataList(1)
    }
  },
  components: { isAllDataTip, isLoadingTip }
};
</script>

<style scoped lang="less">
.warningBox {
  padding: 24px 0;
  min-height: 800px;
  .searchBox {
    padding: 30px 50px;
    background: #fff;
    margin-bottom: 24px;
  }
  .itemBox {
    background: #fff;
    margin: 15px auto;
    .detailMsg {
      padding: 25px 30px;
      background: #fff;
      text-align: justify;
      display: none;
      color: #888;
    }
    .isShow {
      display: block;
    }
    .normalMsg {
      padding: 25px 30px;
      border-bottom: 1px solid rgba(241, 244, 247);
      .company {
        width: 350px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .case,
      .del,
      .type {
        text-align: center;
        width: 270px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .hanlde {
        cursor: pointer;
        color: #529bff;
      }
    }
  }
}
</style>